import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SERVICES_API, SERVICES_BEARER_TOKEN, SERVICES_CUSTOMER_TOKEN, SERVICES_UPLOAD_FILE_LOCATION } from './tokens';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IListResponseData } from '../abstract';
import {
  IAddApplicationDto,
  ICampaignColorSubmitDto,
  IPromotionDataItem,
  IPromotionDetails,
  IPromotionHistoryDataItem,
  IPromotionsDto,
  ISendWarrantyDto,
} from '../promotion';
import { map } from 'rxjs/operators';
import { DocumentTypeEnum, IRegisterProductDto } from '../products';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(
    @Inject(SERVICES_API)
    private _servicesApi: string,
    @Inject(SERVICES_BEARER_TOKEN)
    private _servicesBearerToken: string,
    @Inject(SERVICES_UPLOAD_FILE_LOCATION)
    private _servicesUploadFileLocation: string,
    @Inject(SERVICES_CUSTOMER_TOKEN)
    private _servicesCustomerToken: () => string,
    private readonly _httpClient: HttpClient,
  ) {}

  private static _isPromotionEnded(sDate): boolean {
    const splitDate = sDate.split('-');
    const expirationDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2], 23, 59, 59);
    const currentDate = new Date();
    return currentDate.getTime() > expirationDate.getTime();
  }

  private static _buildPromotionUrl(promotion_url): string | null {
    if (!promotion_url) {
      return null;
    }
    return promotion_url.split('#')[1] || promotion_url || null;
  }
  private static _buildRegisterProductFormData(registerProductDto: IRegisterProductDto): FormData {
    const formData = new FormData();
    formData.append(`purchase_document[file]`, registerProductDto.purchase_document.file);
    formData.append(`purchase_document[purchase_date]`, registerProductDto.purchase_document.purchase_date);
    if (registerProductDto.purchase_document.ecode_id) {
      formData.append(`purchase_document[ecode_id]`, `${registerProductDto.purchase_document.ecode_id}`);
    }
    if (registerProductDto.purchase_document.product_id) {
      formData.append(`purchase_document[product_id]`, `${registerProductDto.purchase_document.product_id}`);
    }
    formData.append(`purchase_document[serial]`, registerProductDto.purchase_document.serial);
    if (registerProductDto.purchase_document.add_ecode) {
      formData.append(`purchase_document[add_ecode]`, registerProductDto.purchase_document.add_ecode);
    }
    if (registerProductDto.purchase_document.ecode) {
      formData.append(`purchase_document[ecode][name]`, registerProductDto.purchase_document.ecode.name);
      if (registerProductDto.purchase_document.ecode.city) {
        formData.append(`purchase_document[ecode][city]`, registerProductDto.purchase_document.ecode.city);
      }
      if (registerProductDto.purchase_document.ecode.street) {
        formData.append(`purchase_document[ecode][street]`, registerProductDto.purchase_document.ecode.street);
      }
      if (registerProductDto.purchase_document.ecode.zip_code) {
        formData.append(`purchase_document[ecode][zip_code]`, registerProductDto.purchase_document.ecode.zip_code);
      }
      if (registerProductDto.purchase_document.ecode.state_id) {
        formData.append(`purchase_document[ecode][state_id]`, `${registerProductDto.purchase_document.ecode.state_id}`);
      }
      formData.append(`purchase_document[ecode][type]`, registerProductDto.purchase_document.ecode.type.toString());
    }
    formData.append(`warranty_card[file]`, registerProductDto.warranty_card.file);
    formData.append(`warranty_card[serial]`, registerProductDto.warranty_card.serial);
    return formData;
  }

  private static _buildApplicationFormData(modifiedDto: IAddApplicationDto): FormData {
    const formData = new FormData();
    formData.append('firstname', modifiedDto.firstname);
    formData.append('lastname', modifiedDto.lastname);
    formData.append('email', modifiedDto.email);
    formData.append('marketing_agreement', modifiedDto.marketing_agreement);
    formData.append('regulation_agreement', modifiedDto.regulation_agreement);
    formData.append('privacy_agreement', modifiedDto.privacy_agreement);
    formData.append('service_agreement', modifiedDto.service_agreement);
    formData.append('mobile', modifiedDto.mobile);
    formData.append('mobile_prefix', modifiedDto.mobile_prefix);
    if (modifiedDto.discount_code) {
      formData.append('discount_code', modifiedDto.discount_code);
    }
    if (modifiedDto.description) {
      formData.append('description', modifiedDto.description);
    }
    if (modifiedDto.bank_account_number) {
      formData.append('bank_account_number', modifiedDto.bank_account_number);
    }
    if (modifiedDto.address) {
      if (modifiedDto.address.zip_code) {
        formData.append('address[zip_code]', modifiedDto.address.zip_code);
      }
      if (modifiedDto.address.street) {
        formData.append('address[street]', modifiedDto.address.street);
      }
      if (modifiedDto.address.house_number) {
        formData.append('address[house_number]', `${modifiedDto.address.house_number}`);
      }
      if (modifiedDto.address.flat_number) {
        formData.append('address[flat_number]', `${modifiedDto.address.flat_number}`);
      }
      if (modifiedDto.address.city) {
        formData.append('address[city]', modifiedDto.address.city);
      }
    }
    if (modifiedDto.us_id) {
      formData.append('us_id', `${modifiedDto.us_id}`);
    }
    if (modifiedDto.documents?.length) {
      modifiedDto.documents.forEach((document, index) => {
        formData.append(`documents[${index}][file]`, document.file);
        formData.append(`documents[${index}][document_type]`, document.document_type);
        if (
          document.document_type === DocumentTypeEnum.PURCHASE ||
          document.document_type === DocumentTypeEnum.WARRANTY_CARD
        ) {
          formData.append(`documents[${index}][serial]`, `${document.serial}`);
          if (document.document_type === DocumentTypeEnum.PURCHASE) {
            formData.append(`documents[${index}][purchase_date]`, document.purchase_date);
            if (document.ecode_id) {
              formData.append(`documents[${index}][ecode_id]`, `${document.ecode_id}`);
            }
            if (document.product_id) {
              formData.append(`documents[${index}][product_id]`, `${document.product_id}`);
            }
            if (document.add_ecode) {
              formData.append(`documents[${index}][add_ecode]`, `${document.add_ecode}`);
            }
            if (document.ecode) {
              formData.append(`documents[${index}][ecode][name]`, `${document.ecode.name}`);
              formData.append(`documents[${index}][ecode][type]`, `${document.ecode.type}`);
              if (document.ecode?.city) {
                formData.append(`documents[${index}][ecode][city]`, `${document.ecode.city}`);
              }
              if (document.ecode?.street) {
                formData.append(`documents[${index}][ecode][street]`, `${document.ecode.street}`);
              }
              if (document.ecode?.zip_code) {
                formData.append(`documents[${index}][ecode][zip_code]`, `${document.ecode.zip_code}`);
              }
              if (document.ecode?.state_id) {
                formData.append(`documents[${index}][ecode][state_id]`, `${document.ecode.state_id}`);
              }
            }
            if (document.amica_products?.length > 0) {
              document.amica_products.forEach((product, productIndex) => {
                if (product) {
                  formData.append(
                    `documents[${index}][amica_products][${productIndex}][product_id]`,
                    `${product.product_id}`,
                  );
                  formData.append(`documents[${index}][amica_products][${productIndex}][price]`, `${product.price}`);
                  if (product.serial) {
                    formData.append(
                      `documents[${index}][amica_products][${productIndex}][serial]`,
                      `${product.serial}`,
                    );
                  }
                }
              });
            }
          }
        }
      });
    }
    if (modifiedDto.awards?.length) {
      modifiedDto.awards.forEach((award, index) => {
        if (award) {
          formData.append(`awards[${index}][id]`, `${award.id}`);
          formData.append(`awards[${index}][file]`, `${award.quantity}`);
        }
      });
    }
    if (modifiedDto.registered_products?.length) {
      modifiedDto.registered_products.forEach((registeredProduct, index) => {
        if (registeredProduct) {
          formData.append(`registered_products[${index}]`, `${registeredProduct}`);
        }
      });
    }
    return formData;
  }

  private static _buildSendWarrantyFormData(modifiedDto: ISendWarrantyDto): FormData {
    const formData = new FormData();
    formData.append('firstname', modifiedDto.firstname);
    formData.append('lastname', modifiedDto.lastname);
    formData.append('email', modifiedDto.email);
    formData.append('phone', modifiedDto.phone);
    formData.append('phonePrefix', modifiedDto.phonePrefix);
    formData.append('street', modifiedDto.street);
    formData.append('houseNumber', modifiedDto.houseNumber);
    if (modifiedDto.flatNumber && modifiedDto.flatNumber.trim()) {
      formData.append('flatNumber', modifiedDto.flatNumber);
    }
    formData.append('zipCode', modifiedDto.zipCode);
    formData.append('city', modifiedDto.city);
    formData.append('serialNumber', modifiedDto.serialNumber);
    formData.append('productCategory', modifiedDto.productCategory);
    formData.append('productType', modifiedDto.productType);
    formData.append('documentWarranty', modifiedDto.documentWarranty);
    formData.append('documentWarrantyInstaller', modifiedDto.documentWarrantyInstaller);
    formData.append('documentPurchase', modifiedDto.documentPurchase);
    formData.append('purchaseDate', modifiedDto.purchaseDate);
    formData.append('agreementOne', `${modifiedDto.agreementOne}`);
    formData.append('agreementOneText', modifiedDto.agreementOneText);
    formData.append('agreementTwo', `${modifiedDto.agreementTwo}`);
    formData.append('agreementTwoText', modifiedDto.agreementTwoText);
    formData.append('bankAccount', modifiedDto.bankAccount);
    formData.append('question1', modifiedDto.question1);
    if (modifiedDto.question2) {
      formData.append('question2', modifiedDto.question2);
    }
    if (modifiedDto.question3) {
      formData.append('question3', modifiedDto.question3);
    }
    if (modifiedDto.question4) {
      formData.append('question4', modifiedDto.question4);
    }
    if (modifiedDto.question5) {
      formData.append('question5', modifiedDto.question5);
    }
    if (modifiedDto.description && modifiedDto.description.trim()) {
      formData.append('description', modifiedDto.description);
    }
    if (modifiedDto.eCode) {
      formData.append('eCode', `${modifiedDto.eCode}`);
    } else if (modifiedDto.newECode) {
      formData.append('newECode[name]', modifiedDto.newECode.name);
      formData.append('newECode[type]', `${modifiedDto.newECode.type}`);
      if (modifiedDto.newECode.city) {
        formData.append('newECode[city]', modifiedDto.newECode.city);
      }
      if (modifiedDto.newECode.street) {
        formData.append('newECode[street]', modifiedDto.newECode.street);
      }
      if (modifiedDto.newECode.zip_code) {
        formData.append('newECode[zip_code]', modifiedDto.newECode.zip_code);
      }
      if (modifiedDto.newECode.state_id) {
        formData.append('newECode[state_id]', `${modifiedDto.newECode.state_id}`);
      }
    }
    return formData;
  }
  private static _buildCampaignColorSubmitFormData(modifiedDto: ICampaignColorSubmitDto): FormData {
    const formData = new FormData();
    formData.append('firstname', modifiedDto.firstname);
    formData.append('lastname', modifiedDto.lastname);
    formData.append('email', modifiedDto.email);
    formData.append('phone', modifiedDto.phone);
    formData.append('phonePrefix', modifiedDto.phonePrefix);
    formData.append('street', modifiedDto.street);
    formData.append('houseNumber', modifiedDto.houseNumber);
    if (modifiedDto.flatNumber && modifiedDto.flatNumber.trim()) {
      formData.append('flatNumber', modifiedDto.flatNumber);
    }
    formData.append('zipCode', modifiedDto.zipCode);
    formData.append('city', modifiedDto.city);
    formData.append('link', modifiedDto.link);
    formData.append('comment', modifiedDto.comment);
    formData.append('agreement', `${modifiedDto.agreement}`);
    formData.append('screen', modifiedDto.screen);
    formData.append('mesh', modifiedDto.mesh);
    formData.append('template', modifiedDto.template);
    return formData;
  }

  private static _isApplicationDtoValid(dto: IAddApplicationDto): boolean {
    if (!dto.firstname || !dto.lastname || !dto.email) {
      return false;
    }
    if (
      dto.marketing_agreement === null ||
      dto.marketing_agreement === undefined ||
      dto.regulation_agreement === null ||
      dto.regulation_agreement === undefined ||
      dto.privacy_agreement === null ||
      dto.privacy_agreement === undefined
    ) {
      return false;
    }
    return !(!dto.mobile || !dto.mobile_prefix);
  }

  /**
   * Get promotion details.
   * @param promotionId - Promotion ID
   */
  public findById(promotionId: number): Observable<IPromotionDetails> {
    return this._httpClient
      .get<IPromotionDetails>(`${this._servicesApi}promotion/${promotionId}`, {
        headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
      })
      .pipe(
        map((value) => ({
          ...value,
          photo_path: value?.photo_path ? this._servicesUploadFileLocation + value.photo_path : null,
        })),
      );
  }

  /**
   * Get list of available promotions
   * @param promotionsDto
   */
  public getPromotions(promotionsDto: IPromotionsDto): Observable<IListResponseData<IPromotionDataItem>> {
    return this._httpClient
      .get<IListResponseData<IPromotionDataItem>>(`${this._servicesApi}promotions`, {
        headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
        params: JSON.parse(JSON.stringify(promotionsDto)),
      })
      .pipe(
        map((value) => ({
          ...value,
          data: value.data.map((promotion) => ({
            ...promotion,
            photo_path: promotion?.photo_path ? this._servicesUploadFileLocation + promotion.photo_path : null,
            promotion_url: PromotionService._buildPromotionUrl(promotion.promotion_url),
            promotion_ended: PromotionService._isPromotionEnded(promotion.end_application_date),
          })),
        })),
      );
  }

  /**
   * Get list of dedicated promotions
   * @param promotionsDto
   */
  public getDedicatedPromotions(promotionsDto: IPromotionsDto): Observable<IListResponseData<IPromotionDataItem>> {
    return this._httpClient
      .get<IListResponseData<IPromotionDataItem>>(`${this._servicesApi}promotions`, {
        headers: {
          Authorization: `Bearer ${this._servicesBearerToken}`,
          CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
        },
        params: JSON.parse(JSON.stringify(promotionsDto)),
      })
      .pipe(
        map((value) => ({
          ...value,
          data: value.data.map((promotion) => ({
            ...promotion,
            photo_path: promotion?.photo_path ? this._servicesUploadFileLocation + promotion.photo_path : null,
            promotion_url: PromotionService._buildPromotionUrl(promotion.promotion_url),
            promotion_ended: PromotionService._isPromotionEnded(promotion.end_application_date),
          })),
        })),
      );
  }

  /**
   * Get customer promotions
   */
  public getUserHistoryPromotions(): Observable<IPromotionHistoryDataItem[]> {
    return this._httpClient.get<IPromotionHistoryDataItem[]>(`${this._servicesApi}my-amica/customer/promotions`, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
        CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
      },
    });
  }

  /**
   * Create application for given promotion ID and return created application ID.
   * Fields mandatory depends of promotion settings.
   * If "CustomerAuthorization" header is set with valid customer token, application will be assigned with logged customer
   * If "SellerAuthorization" header is set with valid seller token, application will be assigned with seller
   *
   * @param promotionId - Promotion ID
   * @param dto
   * @param recaptchaKey
   */
  public addApplication(promotionId: number, dto: IAddApplicationDto, recaptchaKey: string): Observable<string> {
    if (!dto) {
      return throwError(() => new Error('Add application dto is required!'));
    } else if (!PromotionService._isApplicationDtoValid(dto)) {
      return throwError(() => new Error('Add application dto is invalid!'));
    } else if (!promotionId) {
      return throwError(() => new Error('Promotion id is required!'));
    }
    let headers = new HttpHeaders({ Authorization: `Bearer ${this._servicesBearerToken}` });
    const token = this._servicesCustomerToken();
    if (token) {
      headers = headers.append('CustomerAuthorization', `Token ${token}`);
    }
    let formData: FormData | null = null;
    try {
      formData = PromotionService._buildApplicationFormData(dto);
    } catch (e) {
      console.error('Add application form data did not build!', dto, promotionId);
      return throwError(() => new Error('Add application form data did not build!'));
    }
    if (!formData || !formData?.get('firstname')) {
      console.error('Add application form data is invalid!', formData, promotionId);
      return throwError(() => new Error('Add application form data is invalid!'));
    }
    let params = new HttpParams({});
    params = params.append('recaptchaKey', recaptchaKey);
    return this._httpClient.post<string>(`${this._servicesApi}promotion/${promotionId}/application`, formData, {
      headers,
      params,
    });
  }

  /**
   * Add new data from warranty promotion form
   * If "CustomerAuthorization" header is set with valid customer token, application will be assigned with logged customer
   * If "SellerAuthorization" header is set with valid seller token, application will be assigned with seller
   *
   * @param dto
   * @param recaptchaKey
   */
  public sendWarranty(dto: ISendWarrantyDto, recaptchaKey: string): Observable<string> {
    let headers = new HttpHeaders({ Authorization: `Bearer ${this._servicesBearerToken}` });
    const token = this._servicesCustomerToken();
    if (token) {
      headers = headers.append('CustomerAuthorization', `Token ${token}`);
    }
    let formData: FormData | null = null;
    try {
      formData = PromotionService._buildSendWarrantyFormData(dto);
    } catch (e) {
      console.error('Add application form data did not build!', dto);
      return throwError(() => new Error('Send warranty form data did not build!'));
    }
    let params = new HttpParams({});
    params = params.append('recaptchaKey', recaptchaKey);
    return this._httpClient.post<string>(`${this._servicesApi}warranty/submit`, formData, {
      headers,
      params,
    });
  }

  /**
   * Add new data from warranty promotion form
   * Add new data from competition color form
   * @param dto
   * @param recaptchaKey
   */
  public competitionColorSubmit(dto: ICampaignColorSubmitDto, recaptchaKey: string): Observable<string> {
    let headers = new HttpHeaders({ Authorization: `Bearer ${this._servicesBearerToken}` });
    const token = this._servicesCustomerToken();
    if (token) {
      headers = headers.append('CustomerAuthorization', `Token ${token}`);
    }
    let formData: FormData | null = null;
    try {
      formData = PromotionService._buildCampaignColorSubmitFormData(dto);
    } catch (e) {
      console.error('Add application form data did not build!', dto);
      return throwError(() => new Error('Send warranty form data did not build!'));
    }
    let params = new HttpParams({});
    params = params.append('recaptchaKey', recaptchaKey);
    return this._httpClient.post<string>(`${this._servicesApi}competition-color/submit`, formData, {
      headers,
      params,
    });
  }

  /**
   * Register Amica product and return registered product record ID.
   * Purchase document and warranty card is required. Serial number should be unique.
   * @param dto
   */
  public registerProduct(dto: IRegisterProductDto): Observable<string> {
    if (!dto) {
      return throwError(() => new Error('Register product dto is required!'));
    }
    const formData = PromotionService._buildRegisterProductFormData(dto);
    return this._httpClient.post<string>(`${this._servicesApi}my-amica/product/register-new-product`, formData, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
        CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
      },
    });
  }
}
